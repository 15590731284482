<template>
  <b-card>
    <b-row>
      <b-col
        sm="12"
        class="d-flex justify-content-between"
      >
        <router-link
          :to="{
            name: 'expensesByGroup',
            params: {
              projectId: $route.params.projectId,
              groupedExpenseId: expense.id,
            },
          }"
          class="text-primary h2 font-weight-bolder"
        >
          {{ expense.name }}
        </router-link>
        <div>
          <b-button
            :to="{
              name: 'expensesByGroup',
              params: {
                projectId: $route.params.projectId,
                groupedExpenseId: expense.id,
              },
            }"
            variant="outline-primary"
            class="mr-1"
          >
            {{ $t('seeMore') }}
          </b-button>
          <b-button
            v-if="expense.expense_status"
            :variant="statusColor(expense.expense_status)"
          >
            {{ statusName(expense.expense_status) }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col sm="12">
        <span class="font-weight-bolder text-dark h5"> {{ $t('code') }}: </span>
        <span> {{ expense.sku }} </span>
      </b-col>
      <b-col
        sm="12"
        lg="8"
      >
        <span class="font-weight-bolder text-dark h5">
          {{ $t('topQuantity') }}:
        </span>
        <span> {{ expense.top_quantity }} |</span>
        <span class="font-weight-bolder text-dark h5">
          {{ $t('topAmount') }}:
        </span>
        <span> ${{ expense.top_amount | money }} |</span>
        <span class="font-weight-bolder text-dark h5">
          {{ $t('accumulatedQuantity') }}:
        </span>
        <span> {{ expense.purchased_quantity }} |</span>
        <span class="font-weight-bolder text-dark h5">
          {{ $t('accumulatedAmount') }}:
        </span>
        <span> ${{ expense.purchased_amount | money }} |</span>
        <span class="font-weight-bolder text-dark h5">
          {{ $t('remanentQuantity') }}:
        </span>
        <span> {{ expense.top_quantity - expense.purchased_quantity }} |</span>
        <span class="font-weight-bolder text-dark h5">
          {{ $t('remanentAmount') }}:
        </span>
        <span>
          ${{ (expense.top_amount - expense.purchased_amount) | money }}
        </span>
      </b-col>
      <b-col
        sm="12"
        class="mt-2"
      >
        <span class="font-weight-bolder text-dark h5">
          {{ $t('description') }}:
        </span>
        <span class="font-weight-bold text-dark h5">
          {{ expense.description }}
        </span>
      </b-col>
      <b-col
        sm="12"
        class="mt-1 d-flex justify-content-end align-items-center"
      >
        <div>
          <span class="font-weight-bolder text-dark h2">
            {{ $t('pricePerTopUnit') }}:
          </span>
          <span class="text-success h2">
            ${{ (expense.top_amount / expense.top_quantity || 0) | money }}
          </span>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    expense: {
      type: Object,
      default() {
        return {}
      },
    },
  },

  methods: {
    statusColor(status) {
      switch (status) {
        case 'paid':
          return 'success'
        case 'credit':
          return 'warning'
        case 'expired':
          return 'danger'
        default:
          return ''
      }
    },
    statusName(status) {
      switch (status) {
        case 'paid':
          return this.$t('paid')
        case 'credit':
          return this.$t('credit')
        case 'expired':
          return this.$t('expired')
        case 'recieved':
          return this.$t('recieved')
        default:
          return ''
      }
    },
  },
}
</script>

<style></style>
